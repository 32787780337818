import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage, ref, uploadBytes } from '@angular/fire/storage';
import { uuidv4 } from '@firebase/util';
import { from, map } from 'rxjs';

import { MAX_FILE_SIZE_MB, UPLOAD_STATUS, UPLOAD_TYPE } from 'src/app/constants';

import { Upload } from '../models';

import { AuthService } from './auth.service';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(
    private afst: Storage,
    private authService: AuthService,
    private usersService: UsersService,
  ) {}

  // Handle image uploads for the angular-editor wysiwyg.
  uploadWysiwygFile(file: File) {
    const upload = new Upload(
      new File([file], uuidv4(), {
        type: file.type,
        lastModified: file.lastModified,
      }),
    );
    const finishedUpload$ = from(this.uploadFileAsPromise(upload, `wysiwyg_images`));

    return finishedUpload$.pipe(
      map((finishedUpload) => {
        return new HttpResponse({
          body: {
            imageUrl: finishedUpload.url,
          },
        });
      }),
    );
  }

  // return a promise which upload file & get download URL
  async uploadFileAsPromise(upload: Upload, basePath: string, nameOverride?: string): Promise<Upload> {
    const name = nameOverride ?? upload.file.name;

    return uploadBytes(
      ref(this.afst, `${this.authService.tenantId}/${basePath}/${name}`),
      await upload.file.arrayBuffer(),
      { customMetadata: upload.metadata || {} },
    ).then((snapshot) => {
      // upload success
      const bucket = snapshot.ref.bucket;
      upload.path = snapshot.ref.fullPath;

      const encodedPath = encodeURIComponent(`${upload.path}`);
      upload.url = `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${encodedPath}?alt=media`;
      upload.name = name;

      return upload;
      // return snapshot.ref.getDownloadURL().then(downloadURL => {
      //     const res = new Upload(file);
      //     res.url = downloadURL;
      //     res.path = snapshot.ref.fullPath

      //     console.log('GOT RES: ', res);
      //     return res;
      // });
    });
  }

  async upload(uploads: Upload[], basePath: string = 'plan_headers', nameOverride?: string) {
    return Promise.all(
      uploads.map((item) => {
        return this.uploadFileAsPromise(item, basePath, nameOverride);
      }),
    );
  }

  async uploadProfilePicture(userId: string, file: File) {
    if (file.size / 1000 / 1000 > MAX_FILE_SIZE_MB) {
      throw new Error('Files should not be larger than 5mb; try something smaller.');
    }

    const currentUpload = new Upload(file);

    currentUpload.metadata = {
      uploadId: uuidv4(),
      tenantId: this.authService.tenantId,
      createdBy: userId,
      filename: file.name,
      status: UPLOAD_STATUS.NONE,
      type: UPLOAD_TYPE.PROFILE_PIC,
      objectId: userId,
    };

    await this.upload([currentUpload], `profiles/${userId}`);
    await this.usersService.updateUser(userId, {
      photo: currentUpload.url,
      photoPath: currentUpload.path,
    });

    this.authService.profile.photo = currentUpload.url;
    this.authService.profile.photoPath = currentUpload.path;
  }

  async uploadSupportLogo(file: File) {
    if (file.size / 1000 / 1000 > MAX_FILE_SIZE_MB) {
      throw new Error('Files should not be larger than 5mb; try something smaller.');
    }

    const currentUpload = new Upload(file);

    currentUpload.metadata = {
      uploadId: uuidv4(),
      tenantId: this.authService.tenantId,
      createdBy: this.authService.profile.id,
      filename: file.name,
      status: UPLOAD_STATUS.NONE,
      type: UPLOAD_TYPE.PROFILE_PIC,
      objectId: this.authService.tenantId,
    };

    await this.upload([currentUpload], `support-logos/${this.authService.tenantId}`);

    this.authService.tenant.supportLogo = currentUpload.url;
  }
}
